import { type VariantProps, cva } from 'class-variance-authority'
import { type HTMLAttributes, forwardRef } from 'react'

import { cn } from '#utils/utils'

const badgeVariants = cva(
  'focus:ring-ring inline-flex w-fit items-center rounded-full px-2.5 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 select-none shrink-0',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground',
        secondary: 'bg-muted text-secondary-foreground',
        destructive: 'bg-rose-100 text-rose-800',
        warning: 'bg-warning-100 text-warning-800',
        outline: 'text-foreground border',
        success: 'bg-success-100 text-success-800',
        indigo: 'bg-indigo-100 text-indigo-800',
        violet: 'bg-violet-100 text-violet-800',
        slate: 'bg-slate-100 text-slate-800',
        stone: 'bg-stone-100 text-stone-800',
        brand: 'bg-brand-background text-brand'
      },
      size: {
        xs: 'h-4 text-xxs',
        sm: 'h-5 text-xs',
        default: 'h-6 text-sm'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface BadgeProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(badgeVariants({ variant, size }), className)}
        {...props}
      />
    )
  }
)

export { Badge, badgeVariants }
